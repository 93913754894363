import * as React from 'react';
import { graphql, Link, navigate } from 'gatsby';
import '../styles';
import Section from '../components/Section/Section';
import PrimaryButton from '../components/PrimaryButton/PrimaryButton';
import SEO from '../components/SEO';
import LeftIllustration from '../assets/left_illustration.svg';
import RightIllustration from '../assets/right_illustration.svg';

const Page = ({ data }) => {
  const { title, image, message, actionButton, seo } = data.content.frontmatter;
  const { logoFixed } = data.header.frontmatter;

  const url = typeof window !== 'undefined' ? window.location.href : '';

  return (
    <div className="layout-error">
      <SEO seo={seo} url={url} />
      <Section>
        <img
          className="left-illustration"
          src={LeftIllustration}
          alt="left illustration"
        />
        <img
          className="right-illustration"
          src={RightIllustration}
          alt="right illustration"
        />
        <div className="error-content-wrapper">
          <div className="logo-wrapper">
            <Link to="/">
              <img src={logoFixed.image} alt="PipeCodes Logo" />
            </Link>
          </div>
          <img className="error-image" src={image.image} alt={image.imageAlt} />

          <span className="error-title">{title}</span>
          <span
            className="error-message"
            dangerouslySetInnerHTML={{ __html: message }}
          />

          <PrimaryButton
            size="xs"
            text={actionButton.text}
            onClick={() => navigate(actionButton.href)}
          />
        </div>
      </Section>
    </div>
  );
};

export default Page;

export const query = graphql`
  query ErrorPageQuery($id: String!, $language: String!) {
    content: markdownRemark(
      id: { eq: $id }
      frontmatter: { language: { eq: $language } }
    ) {
      frontmatter {
        seo {
          title
          description
          facebookAppID
          image
          keywords
          twitterUsername
        }
        title
        linkUrl
        language
        message
        image {
          image
          imageAlt
        }
        actionButton {
          text
          href
        }
      }
    }
    header: markdownRemark(
      frontmatter: {
        templateKey: { eq: "navbar" }
        language: { eq: $language }
      }
    ) {
      frontmatter {
        language
        logo {
          image
          imageAlt
        }
        logoFixed {
          image
          imageAlt
        }
      }
    }
  }
`;
